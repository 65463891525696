import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PDFDisplay from './PDF_display/pdf-display';

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [audioFiles, setAudioFiles] = useState([]);
  const [pdfPath, setPdfPath] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState('lily');

  axios.defaults.withCredentials = true;

  const fetchVoices = async () => {
    try {
      const response = await axios.get('https://pdf-reader-backend-8117907d52b8.herokuapp.com/voices');
      setVoices(response.data.voices);
      if (response.data.voices.length > 0) {
        setSelectedVoice(response.data.voices[0].id);
      }
    } catch (error) {
      console.error('Error fetching voices:', error);
      setErrorMessage('Failed to fetch voice options');
    }
  };

  useEffect(() => {
    fetchVoices();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('File selected:', file);
    
    if (file) {
      setSelectedFile(file);
      // Create a local URL for immediate PDF preview
      const pdfUrl = URL.createObjectURL(file);
      setPdfPath(pdfUrl);
      setAudioFiles([]);
      setErrorMessage(null);
    }
  };

  const handleUpload = async () => {
    console.log('Upload started, selectedFile:', selectedFile);
    
    if (!selectedFile) {
      setErrorMessage('Please select a PDF file first.');
      return;
    }

    console.log('File type:', selectedFile.type);

    if (!selectedFile.type || selectedFile.type !== 'application/pdf') {
      setErrorMessage('Please select a valid PDF file.');
      return;
    }

    const formData = new FormData();
    formData.append('pdf', selectedFile);
    formData.append('voice', selectedVoice);

    // Set PDF path immediately after file selection
    const pdfUrl = URL.createObjectURL(selectedFile);
    setPdfPath(pdfUrl);

    try {
      setErrorMessage('Uploading file...');
      const response = await axios.post('https://pdf-reader-backend-8117907d52b8.herokuapp.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log('Upload progress:', percentCompleted);
        }
      });

      console.log('Upload response:', response.data);

      if (response.data.audio_files && response.data.audio_files.length > 0) {
        setAudioFiles(response.data.audio_files);
        // Update PDF path to use server URL after upload is complete
        setPdfPath(`https://pdf-reader-backend-8117907d52b8.herokuapp.com/uploads/${response.data.audio_files[0].pdf_path.split('/').pop()}`);
        setErrorMessage(null);
      } else {
        setErrorMessage('No audio files were generated.');
      }
    } catch (error) {
      console.error('Upload error:', error);
      setErrorMessage(
        error.response?.data?.error || 
        'Failed to upload file. Please try again.'
      );
    }
  };

  const handleDownload = async (id, filename) => {
    try {
      const response = await axios.get(`https://pdf-reader-backend-8117907d52b8.herokuapp.com/download/${id}`, {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error during file download:', error);
      if (error.response && error.response.data && typeof error.response.data.error === 'string') {
        setErrorMessage(error.response.data.error);
      } else if (error.message && typeof error.message === 'string') {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An error occurred during the file download.');
      }
    }
  };

  const handlePlay = (id) => {
    const audioUrl = `https://pdf-reader-backend-8117907d52b8.herokuapp.com/stream/${id}`;
    if (currentlyPlaying === id) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(audioUrl);
      audioRef.current.addEventListener('timeupdate', updateProgress);
      audioRef.current.addEventListener('loadedmetadata', () => {
        setDuration(audioRef.current.duration);
      });
      audioRef.current.addEventListener('ended', () => {
        setCurrentlyPlaying(null);
        setIsPlaying(false);
        setCurrentTime(0);
      });
      audioRef.current.play();
      setCurrentlyPlaying(id);
      setIsPlaying(true);
    }
  };

  const updateProgress = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleProgressChange = (e) => {
    const newTime = (e.target.value / 100) * duration;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener('timeupdate', updateProgress);
      }
    };
  }, []);

  const renderVoiceSelection = () => {
    const handleVoiceChange = (e) => {
      setSelectedVoice(e.target.value);
    };

    return (
      <div className="mb-6">
        <label htmlFor="voice-select" className="block text-sm font-medium text-gray-700 mb-2">
          Voice Selection
        </label>
        <select 
          id="voice-select"
          value={selectedVoice}
          onChange={handleVoiceChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        >
          {voices.map(voice => (
            <option key={voice.id} value={voice.id}>
              {voice.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8 bg-white rounded-lg shadow-sm p-4">
          <div className="flex items-center space-x-4">
            <h1 className="text-2xl font-bold text-gray-900">PDF to Audio Converter</h1>
          </div>
        </div>

        {/* Main content */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
          {renderVoiceSelection()}
          {/* File upload form */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Upload PDF
              </label>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                className="mt-1 block w-full"
              />
            </div>
            
            {selectedFile && (
              <button
                onClick={handleUpload}
                className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
              >
                Convert to Audio
              </button>
            )}

            {errorMessage && (
              <div className="text-red-600">{errorMessage}</div>
            )}

          {/* Audio Files Section */}
          {audioFiles.length > 0 && (
            <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Audio Files</h2>
              <ul className="space-y-4">
                {audioFiles.map((audio) => (
                  <li key={audio.id} className="border rounded-lg p-4">
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-gray-700">{audio.filename.split('_')[0]}</span>
                      <div className="space-x-2">
                        <button 
                          onClick={() => handleDownload(audio.id, audio.filename)}
                          className="px-3 py-1 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-md transition-colors"
                        >
                          Download
                        </button>
                        <button 
                          onClick={() => handlePlay(audio.id)}
                          className="px-3 py-1 bg-indigo-100 hover:bg-indigo-200 text-indigo-700 rounded-md transition-colors"
                        >
                          {currentlyPlaying === audio.id ? (isPlaying ? 'Pause' : 'Resume') : 'Play'}
                        </button>
                      </div>
                    </div>
                    {currentlyPlaying === audio.id && (
                      <div className="space-y-2">
                        <input
                          type="range"
                          min="0"
                          max="100"
                          value={(currentTime / duration) * 100 || 0}
                          onChange={handleProgressChange}
                          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                        />
                        <div className="flex justify-between text-sm text-gray-500">
                          <span>{formatTime(currentTime)}</span>
                          <span>{formatTime(duration)}</span>
                        </div>
                      </div>
                    )}
                  </li>
                ))}

              </ul>
            </div>
          )}
          {pdfPath && (
            <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">PDF Preview</h2>
              <PDFDisplay pdfPath={pdfPath} />
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
